body {
    margin: 0;
}

#root {
    max-width: 500px;
    margin: auto;
}

.action {
    background: aquamarine;
}

.project {
    background: pink;
}

.done {
    text-decoration: line-through;
    opacity: .2;
}

.stalled {
    background: red;
}

a {
    color: black;
}

